/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.app-BitBucket-layout {
  min-height: 100vh;
  position: relative;
  background-color: transparent;
  overflow-x: hidden;
}
.app-BitBucket-layout.appMainFixedFooter {
  padding-bottom: 46px;
}
.app-BitBucket-layout.appMainFixedFooter .app-main-footer {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
  width: 100%;
}
.ant-layout-sider-zero-width-trigger {
  display: none;
}
.app-BitBucket-sidebar-wrapper {
  display: none;
  width: 320px !important;
  -webkit-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  -ms-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  transition: all 0.1s linear;
  position: relative;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
@media screen and (min-width: 1200px) {
  .app-BitBucket-sidebar-wrapper {
    display: flex;
  }
}
.app-BitBucket-sidebar-wrapper-close {
  width: 72px !important;
}
.app-BitBucket-sidebar-wrapper-close .app-BitBucket-sidebar.ant-layout-sider {
  min-width: 0 !important;
  width: 8px !important;
  -webkit-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  -ms-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  transition: all 0.1s linear;
  overflow: hidden;
}
.bitbucket-btn {
  position: absolute;
  top: 20px;
  right: -12px;
  z-index: 3;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffd500;
  color: #fff;
  padding: 2px;
  font-size: 24px;
  border-radius: 50%;
}
[dir=rtl] .bitbucket-btn {
  right: auto;
  left: -12px;
}
.bitbucket-btn:hover,
.bitbucket-btn:focus {
  background-color: #ffd500;
  color: #fff;
}
.bucket-minibar {
  display: flex;
  flex-direction: column;
  width: 64px;
  min-width: 64px;
  background-color: #001529;
  color: inherit;
  border-right: 1px solid rgba(0, 21, 41, 0.15);
  position: relative;
}
.bucket-minibar:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.05);
}
.bucket-minibar.dark:before {
  background-color: rgba(255, 255, 255, 0.05);
}
.bucket-minibar .bucket-minibar-inner {
  position: relative;
  z-index: 3;
  display: flex;
  flex-direction: column;
}
[dir=rtl] .bucket-minibar {
  border-right: 0 none;
  border-left: 1px solid rgba(0, 21, 41, 0.15);
}
.bucket-minibar a {
  color: inherit;
}
.bucket-minibar-logo {
  min-height: 56px;
  padding: 10px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (min-width: 768px) {
  .bucket-minibar-logo {
    min-height: 71px;
  }
}
.bucket-minibar-scrollbar {
  display: flex;
  flex-direction: column;
  padding: 12px;
  height: calc(100vh - 56px);
}
@media screen and (min-width: 768px) {
  .bucket-minibar-scrollbar {
    height: calc(100vh - 71px);
  }
}
.bucket-minibar-main {
  text-align: center;
  display: flex;
  flex-direction: column;
}
.bucket-minibar-main .bucket-minibar-link {
  margin-bottom: 10px;
  font-weight: 500;
  text-transform: uppercase;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: inherit;
  padding: 9px;
  border-radius: 50%;
  line-height: 1;
}
.bucket-minibar-main .bucket-minibar-notify-link {
  font-size: 20px;
}
.bucket-minibar-main .langBtn {
  margin: 0 0 10px;
  background-color: transparent;
}
.bucket-minibar-main .langBtn:hover,
.bucket-minibar-main .langBtn:focus {
  border-color: transparent;
  color: inherit;
}
.bucket-minibar-main .lang-text {
  display: none;
}
.bucket-minibar-setting {
  text-align: center;
  margin-top: auto;
}
.app-BitBucket-sidebar.ant-layout-sider {
  flex: 0 0 auto !important;
  max-width: none !important;
  min-width: 256px !important;
  width: auto !important;
  -webkit-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  -ms-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  transition: all 0.1s linear;
}
@media screen and (min-width: 1200px) and (max-width: 1300px) {
  .app-BitBucket-sidebar.ant-layout-sider {
    width: 190px !important;
  }
}
.app-BitBucket-sidebar .ant-layout-sider-trigger {
  display: none;
}
.app-BitBucket-sidebar.ant-layout-sider-has-trigger {
  padding-bottom: 0;
}
.app-BitBucket-sidebar.ant-layout-sider-collapsed {
  width: 0 !important;
  -webkit-transition: all 200ms linear;
  -moz-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  -o-transition: all 200ms linear;
  transition: all 200ms linear;
}
@media screen and (min-width: 992px) {
  .app-BitBucket-sidebar.ant-layout-sider-collapsed {
    width: 4rem !important;
  }
}
.app-BitBucket-sidebar.ant-layout-sider-collapsed + .app-layout-main {
  width: 100% !important;
  -webkit-transition: all 200ms linear;
  -moz-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  -o-transition: all 200ms linear;
  transition: all 200ms linear;
}
@media screen and (min-width: 992px) {
  .app-BitBucket-sidebar.ant-layout-sider-collapsed + .app-layout-main {
    width: calc(100% - 4rem) !important;
  }
}
.app-BitBucket-sidebar.ant-layout-sider-collapsed .cr-user-info {
  padding-left: 12px;
  padding-right: 12px;
}
.app-BitBucket-sidebar.ant-layout-sider-collapsed .cr-user-info-content {
  opacity: 0;
  visibility: hidden;
  width: 0;
  padding-left: 0;
  padding-right: 0;
}
.app-BitBucket-sidebar.ant-layout-sider-collapsed .ant-menu-item-group-title {
  opacity: 0;
  visibility: hidden;
  width: 0;
  height: 0;
  padding: 0;
}
.app-BitBucket-header {
  padding-left: 20px;
  padding-right: 20px;
  color: #111827;
  background-color: #fff;
  height: 56px;
  line-height: 1;
  -webkit-box-shadow: 4px 3px 4px 0px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 4px 3px 4px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 4px 3px 4px 0px rgba(0, 0, 0, 0.12);
  -webkit-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  -ms-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  transition: all 0.1s linear;
  position: sticky;
  right: 0;
  left: 0;
  top: 0;
  z-index: 9;
  display: flex;
  align-items: center;
}
@media screen and (min-width: 768px) {
  .app-BitBucket-header {
    padding-left: 30px;
    padding-right: 30px;
    height: 71px;
  }
}
@media screen and (min-width: 1200px) {
  .app-BitBucket-header {
    display: none;
  }
}
.app-BitBucket-header .trigger {
  font-size: 20px;
  margin-right: 20px;
  padding: 5.5px;
  color: #111827;
}
.app-BitBucket-header .trigger svg {
  display: block;
}
[dir=rtl] .app-BitBucket-header .trigger {
  margin-right: 0;
  margin-left: 20px;
}
@media screen and (min-width: 1200px) {
  .app-BitBucket-header .trigger {
    display: none;
  }
}
.bitBucket-header-search {
  position: relative;
  max-width: 140px;
  min-height: 36px;
  margin-left: auto;
  margin-right: 10px;
}
[dir=rtl] .bitBucket-header-search {
  margin-left: 10px;
  margin-right: auto;
}
@media screen and (min-width: 576px) {
  .bitBucket-header-search {
    margin-right: 20px;
    max-width: 165px;
  }
  [dir=rtl] .bitBucket-header-search {
    margin-right: auto;
    margin-left: 20px;
  }
}
.bitBucket-header-search .ant-input-wrapper {
  top: 50%;
  right: 0;
  z-index: 1;
  position: absolute;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
[dir=rtl] .bitBucket-header-search .ant-input-wrapper {
  right: auto;
  left: 0;
}
.bitBucket-header-search .ant-input {
  padding: 8px 14px;
  height: 36px;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
  width: 104px;
}
.bitBucket-header-search .ant-input:focus {
  width: 135px;
}
@media screen and (min-width: 576px) {
  .bitBucket-header-search .ant-input {
    width: 129px;
  }
  .bitBucket-header-search .ant-input:focus {
    width: 165px;
  }
}
.bitBucket-header-search .ant-input-search-button {
  height: 36px;
  width: 36px;
  box-shadow: none;
}
.bitBucket-header-search .ant-input-search-button .anticon svg {
  display: block;
}
.bitBucket-header-search.ant-input-search-rtl > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button {
  border-radius: 4px 0 0 4px;
}
.app-BitBucket-header-sectionDesktop {
  display: none;
}
@media screen and (min-width: 768px) {
  .app-BitBucket-header-sectionDesktop {
    display: flex;
    align-items: center;
  }
}
.app-BitBucket-header-section-mobile {
  display: block;
}
@media screen and (min-width: 768px) {
  .app-BitBucket-header-section-mobile {
    display: none;
  }
}
.app-BitBucket-header-section-mobile .ant-dropdown-link {
  font-size: 24px;
  color: #111827;
}
.app-BitBucket-header-section-mobile .ant-dropdown-link svg {
  display: block;
}
.app-BitBucket-layout-main {
  -webkit-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  -ms-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  transition: all 0.1s linear;
  width: 100% !important;
  position: relative;
}
@media screen and (min-width: 1200px) {
  .app-BitBucket-layout-main {
    width: calc(100% - 320px) !important;
  }
}
@media screen and (min-width: 1200px) {
  .app-BitBucket-layout-main .apps-container {
    height: calc(100vh - 78px);
  }
}
.bitBucket-main-scrollbar {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  max-height: calc(100vh - 56px);
}
.appMainFixedFooter .bitBucket-main-scrollbar {
  max-height: calc(100vh - 102px);
}
@media screen and (min-width: 768px) {
  .bitBucket-main-scrollbar {
    max-height: calc(100vh - 71px);
  }
  .appMainFixedFooter .bitBucket-main-scrollbar {
    max-height: calc(100vh - 116px);
  }
}
@media screen and (min-width: 1200px) {
  .bitBucket-main-scrollbar {
    max-height: 100vh;
  }
  .appMainFixedFooter .bitBucket-main-scrollbar {
    max-height: calc(100vh - 48px);
  }
}
.app-BitBucket-sidebar-scrollbar {
  height: calc(100vh - 56px);
}
.appMainFixedFooter .app-BitBucket-sidebar-scrollbar {
  height: calc(100vh - 102px);
}
@media screen and (min-width: 768px) {
  .app-BitBucket-sidebar-scrollbar {
    height: calc(100vh - 71px);
  }
  .appMainFixedFooter .app-BitBucket-sidebar-scrollbar {
    height: calc(100vh - 116px);
  }
}
.bitBucket-sidebar-img-background {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: relative;
}
.bitBucket-sidebar-img-background:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: inherit;
  z-index: 1;
  opacity: 0.5;
}
.bitBucket-sidebar-img-background > div {
  position: relative;
  z-index: 3;
}
.bitBucket-sidebar-img-background .ant-menu.ant-menu-dark,
.bitBucket-sidebar-img-background .ant-menu-dark .ant-menu-sub,
.bitBucket-sidebar-img-background .ant-menu.ant-menu-dark .ant-menu-sub {
  background-color: transparent;
  color: inherit;
}
.bitBucket-sidebar-img-background .app-main-sidebar-menu.ant-menu-dark .ant-menu-item-group-title,
.bitBucket-sidebar-img-background .app-main-sidebar-menu .ant-menu-item a {
  color: inherit;
}
.app-BitBucket-drawer .ant-drawer-content-wrapper {
  width: 20rem !important;
}
.app-BitBucket-drawer .ant-drawer-body {
  padding: 0;
}
.app-BitBucket-drawer .app-BitBucket-sidebar-wrapper {
  display: flex;
  width: auto !important;
}
.app-BitBucket-drawer .cr-user-info {
  padding-top: 4px;
  padding-bottom: 4px;
}
