/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.error-boundary {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  flex: 1;
}
.error-boundary svg {
  fill: #ffd500;
  width: 100%;
  max-width: 400px;
}
