.custom-page-header {
  display: flex;
  align-items: center;
  background: #FFF9C4;
  color: #000;
  height: 40px;
  padding: 0 0.5rem;
  margin-bottom: 0rem;

  .icon {
    font-size: 1.09375rem;
  }

  .title {
    font-size: 1.09375rem;
    margin-bottom: 0.10938rem;
    color:#000;
    margin-left: 5px;
  }

  .ant-btn-sm {
    border-radius: 20px;
    margin-left: 5px;
  }
}
@primary-color: #ffd500;