.pointer {
  cursor: pointer;
}

img {
  max-width: 100%;
}

.item-hover {
  transition: all .2s ease;
  transform: scale(1);

  &:hover {
    background-color: fade(@primary-color, 10);
    transform: translateY(-2px);
    box-shadow: 0 3px 10px 0 fade(@black, 20);
  }
}

.card-hover {
  transition: all 0.3s ease;
  transform: scale(1);

  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, .45);
    transform: scale(1.05);
  }
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-left {
  text-align: left;

  [dir=rtl] & {
    text-align: right;
  }
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;

  [dir=rtl] & {
    text-align: left;
  }
}

.background-image {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.cr-btn {
  box-sizing: border-box;
  box-shadow: 0 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12);
  text-transform: uppercase;
}

.cr-btn-circle {
  background-color: transparent;
  border-color: transparent;
  color: @grey-500;
  box-shadow: none;

  &:hover, &:focus {
    background-color: fade(@tooltip-bg, 4);
    border-color: fade(@tooltip-bg, 4);
    color: @text-color;
  }

  & .anticon {
    font-size: 20px;
  }
}

.btn-secondary {
  background-color: @secondary-color;
  border-color: @secondary-color;
  color: @white;

  &:hover, &:focus {
    background-color: darken(@secondary-color, 25);
    border-color: darken(@secondary-color, 25);
    color: @white;
  }
}

.btn-light-blue {
  background-color: fade(@primary-color, 10);
  color: @primary-color;
  border-color: fade(@primary-color, 10);

  &:hover, &:focus {
    background-color: fade(@primary-color, 20);
    color: @primary-color;
    border-color: fade(@primary-color, 20);
  }
}

.btn-secondary-outline {
  background-color: transparent;
  border-color: @secondary-color;
  color: @secondary-color;
  box-shadow: none;

  &:hover, &:focus {
    background-color: transparent;
    border-color: darken(@secondary-color, 15);
    color: darken(@secondary-color, 15);
  }
}

.btn-primary-outline {
  background-color: transparent;
  border-color: @primary-color;
  color: @primary-color;
  box-shadow: none;

  &:hover, &:focus {
    background-color: transparent;
    border-color: darken(@primary-color, 15);
    color: darken(@primary-color, 15);
  }
}

.btn-white-outline {
  background-color: transparent;
  border-color: @white;
  color: @white;
  box-shadow: none;

  &:hover, &:focus {
    background-color: transparent;
    border-color: @white;
    color: @white;
  }
}

.mb-0 {
  margin-bottom: 0 !important;
}

.dot {
  height: 12px;
  width: 12px;
  display: block;
  border-radius: @border-radius-circle;
}

.cr-dropdown-link {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: @border-radius-circle;
  color: fade(@tooltip-bg, 84) !important;
  font-size: 20px;

  &:hover {
    background-color: fade(@tooltip-bg, 10);
  }
}

.close-btn {
  background-color: transparent;
  box-shadow: none;
  border: 0 none;
  padding: 0;
  height: auto;

  &:hover, &:focus {
    border: 0 none;
  }

  & .anticon {
    font-size: 20px;
  }
}

.page-title {
  color: @text-color;
  font-weight: @font-weight-bold;
  margin-bottom: 16px;
  font-size: @font-size-lg;
  display: inline-block;
}

.ant-checkbox-inner {
  width: 18px;
  height: 18px;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  width: 12px;
  height: 12px;
}

.ant-modal {
  max-width: 90%;
}

.text-secondary {
  color: @text-color-secondary;
}

.text-green {
  color: @green-6;
}

.slick-slider-global .slick-dots li {
  width: 12px;
  height: 12px;
  margin: 0 2px;
  vertical-align: middle;

  & button {
    width: 12px;
    height: 12px;
    padding: 0;
  }

  & button::before {
    width: 12px;
    height: 12px;
    font-size: 10px;
    line-height: 12px;
    color: #be8658;
  }
}

.slick-slider-global .slick-dots li.slick-active button::before {
  color: #825a44;
  opacity: 0.75;
}

.z-index-20 {
  z-index: 20;
}

.ant-picker {
  padding-top: @input-padding-vertical-base;
  padding-bottom: @input-padding-vertical-base;
}

.ant-pagination {
  & li {
    margin-bottom: 4px;
    margin-top: 4px;
  }

  &.mini {
    & .ant-pagination-item,
    & .ant-pagination-prev,
    & .ant-pagination-next {
      margin-bottom: 4px;
      margin-top: 4px;
    }
  }
}

.ant-drawer,
.ant-modal-wrap,
.ant-modal-mask,
.ant-picker-dropdown,
.ant-select-dropdown,
.ant-message {
  z-index: 1051;
}

.ant-drawer-close {
  color: @text-color-secondary;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  top: 11px;
  right: 6px;
  padding: 5px;

  &:hover,
  &:focus {
    background-color: fade(@black, 5);
    color: @text-color;

  }
}

// Ant Tooltip
.ant-tooltip-inner {
  & a {
    color: @text-color-white;
    display: flex;
    align-items: center;

    & .ant-menu-item-icon {
      margin-right: 6px;

      & .anticon, & svg {
        display: block;
      }
    }
  }
}

ิbody {

}

.app-layout-hor-main .main-content-view {
  padding-top: 0 !important;
}

.custom-container {
  padding-left: 15px;
  padding-right: 15px;

  &.col-no-mb {
     .ant-row {
        .ant-col {
          margin-bottom: 0 !important;
        }
     }
  }

}

.ant-layout.app-layout-hor {
  padding-bottom: 100px;
}

.ant-row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.badge {
  display: flex;
  padding: 0.5em 0.4em 0.25em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.5rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;

  &.badge-warning {
    color: #212529;
    background-color: #ffc107;
  }
}

.d-flex {
  display: flex !important;
}

.align-items-center {
  align-items: center !important;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  margin-bottom: 0.25rem;
  font-weight: 400;
  line-height: 1.2;
}

.ml-1, .mx-1 {
  margin-left: 0.125rem!important;
}

.ml-2, .mx-2 {
  margin-left: 0.25rem!important;
}

.ml-3, .mx-3 {
  margin-left: 0.5rem!important;
}

.ml-4, .mx-4 {
  margin-left: 0.75rem!important;
}

.ml-5, .mx-5 {
  margin-left: 1.5rem!important;
}

.mr-1, .mx-1 {
  margin-right: 0.125rem!important;
}

.mr-2, .mx-2 {
  margin-right: 0.25rem!important;
}

.mr-3, .mx-3 {
  margin-right: 0.5rem!important;
}

.mr-4, .mx-4 {
  margin-right: 0.75rem!important;
}

.mr-5, .mx-5 {
  margin-right: 1.5rem!important;
}

.ml-auto, .mx-auto {
  margin-left: auto!important;
}

.mr-auto, .mx-auto {
  margin-right: auto!important;
}

.mb-1, .my-1 {
  margin-bottom: 0.125rem!important;
}

.mb-2, .my-2 {
  margin-bottom: 0.25rem!important;
}

.mb-3, .my-3 {
  margin-bottom: 0.5rem!important;
}

.mb-4, .my-4 {
  margin-bottom: 0.75rem!important;
}

.mb-5, .my-5 {
  margin-bottom: 1.5rem!important;
}

.mt-1, .my-1 {
  margin-top: 0.125rem!important;
}

.mt-2, .my-2 {
  margin-top: 0.25rem!important;
}

.mt-3, .my-3 {
  margin-top: 0.5rem!important;
}

.mt-4, .my-4 {
  margin-top: 0.75rem!important;
}

.mt-5, .my-5 {
  margin-top: 1.5rem!important;
}


.pt-1, .py-1 {
  padding-top: 0.125rem!important;
}

.pt-2, .py-2 {
  padding-top: 0.25rem!important;
}

.pt-3, .py-3 {
  padding-top: 0.5rem!important;
}

.pt-4, .py-4 {
  padding-top: 0.75rem!important;
}

.pt-5, .py-5 {
  padding-top: 1.5rem!important;
}

.pl-1, .px-1 {
  padding-left: 0.125rem!important;
}

.pl-2, .px-2 {
  padding-left: 0.25rem!important;
}

.pl-3, .px-3 {
  padding-left: 0.5rem!important;
}

.pl-4, .px-4 {
  padding-left: 0.75rem!important;
}

.pl-5, .px-5 {
  padding-left: 1.5rem!important;
}

.pr-1, .px-1 {
  padding-right: 0.125rem!important;
}

.pr-2, .px-2 {
  padding-right: 0.25rem!important;
}

.pr-3, .px-3 {
  padding-right: 0.5rem!important;
}

.pr-4, .px-4 {
  padding-right: 0.75rem!important;
}

.pr-5, .px-5 {
  padding-right: 1.5rem!important;
}

.pb-1, .py-1 {
  padding-bottom: 0.125rem!important;
}

.pb-2, .py-2 {
  padding-bottom: 0.25rem!important;
}

.pb-3, .py-3 {
  padding-bottom: 0.5rem!important;
}

.pb-4, .py-4 {
  padding-bottom: 0.75rem!important;
}

.pb-5, .py-5 {
  padding-bottom: 1.5rem!important;
}

.ant-layout {
  background: #FFF59D !important;
}

.text-primary {
  color: #ffd500!important;
}

.text-danger {
  color: #dc3545!important;
}

.text-warning {
  color: #ffc107!important;
}

.text-success {
  color: #28a745!important;
}

.text-muted {
  color: #6c757d!important;
}

.text-info {
  color: #17a2b8!important;
}

.btn-brand {
  color: #000;
  background: linear-gradient(180deg,#FFD600,#FFEA00);
  border-color: #FFD600;
}

.ant-btn-primary {
  color:#000;
  background: linear-gradient(180deg,#FFD600,#FFEA00);
  border-color: #FFD600;
}

.ant-btn-primary:hover, .ant-btn-primary:focus {
  color:#000;
}

.ant-btn:hover, .ant-btn:focus {
  color:#000;
}



.lotto__card {
  display: flex;
  flex-direction: column;
  background: #fff;
  color: #212529;
  height: 100px;
  margin-bottom: 0.5rem;
  border-radius: 0.5rem;

  .ant-row {
    .ant-col {
      margin-bottom: 0;
    }
  }

  &.disabled {
    background: #e9ecef;
    filter: grayscale(50%);
  }

  .lotto-header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;

    .title {
      margin-bottom: 0;
    }

  }

  .lotto-body {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.5rem;

    .date {
      background: #dee2e6;
      color: #6c757d;
      padding: 0 0.5rem;
      border-radius: 0.3rem;
    }

    .countdown {
      background: #e9ecef;
      color: #0081c6;
      font-family: "Lato",Arial,sans-serif;
      width: 100%;
      padding: 0.25rem 0.5rem;
      text-align: center;
      margin: 0 0.5rem;
      border-radius: 0.3rem;
    }

  }

  .lotto-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: .76562rem;
    padding: 0 0.5rem;

    .footer-result {
      display: flex;
      width: 100%;
      border-top: 1px solid #dee2e6;

      .result-col {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0.125rem 0;
      }

    }

  }

}

.lotto__card-gov {
  display: flex;
  flex-direction: column;
  background: #fff;
  color: #212529;
  height: 150px;
  margin-bottom: 0.5rem;
  border-radius: 0.5rem;

  .lotto-header {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem;
    margin-bottom: 0.5rem;

    .date {
      background: #dee2e6;
      color: #6c757d;
      font-size: .76562rem;
      padding: 0 0.5rem;
      margin-left: auto;
      border-radius: 0.3rem;
    }
  }

  .lotto-body {
    margin: auto 0;

    .ant-col {
      margin-bottom: 0;
    }

    .btn-lotto-bet,
    .countdown {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.25rem;
      border-radius: 0.3rem;
    }

    .countdown {
      background: #e9ecef;
      color: #0081c6;
      font-family: "Lato",Arial,sans-serif;
      font-size: 1.09375rem;
      margin: 0 1rem;
    }

    .btn-lotto-bet {
      height: 100%;
      margin-right: 1rem;
    }

  }

  .lotto-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: .76562rem;
    padding: 0.5rem;
  }




}


.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.5rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}

.badge-primary {
  color: #fff;
  background-color: #0081c6;
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}

.badge-lg {
  font-size: 90%!important;
}

.history-type-buttons {
  margin-top: 10px;

  .ant-col {
    margin-bottom: 0;
  }

  .ant-btn {
    height: auto;
    display: flex;
    align-items: center;
    justify-items: center;
    border-radius: 30px;
    padding-top: 5px;
    padding-bottom: 5px;

    div {
      flex: 1 1;
    }
  }
}

.card-empty-box {

  .ant-card-body {
    padding: 0;
  }

  .card-empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem 0;

    .icon {
      color: #dee2e6;
      font-size: 4.375rem;
      margin-bottom: 0.875rem;
    }

    .label {
      color: #212529;
      font-size: .875rem;
    }

  }

}

.d-flex {
  display: flex!important;
}

.justify-content-between {
  justify-content: space-between!important;
}

.align-items-center {
  align-items: center!important;
}

.justify-content-center {
  justify-content: center!important;
}

.flex-shrink-1 {
  flex-shrink: 1!important;
}

.flex-column {
  flex-direction: column!important;
}

.table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 0.5rem;
  color: #212529;
}

.table td,
.table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-bordered,
.table-bordered td,
.table-bordered th {
  border: 1px solid #dee2e6;
}

.table-bordered thead td,
.table-bordered thead th {
  border-bottom-width: 2px;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0,0,0,.05);
}

.custom-message-info {
  text-align: right !important;

  .ant-message-notice-content {
    background-color: #e6f7ff;
    border: 1px solid #91d5ff;
  }
}

.custom-message-error {
  text-align: right !important;

  .ant-message-notice-content {
    background-color: #fff2f0;
    border: 1px solid #ffccc7;
  }
}

.custom-message-success {
  text-align: right !important;

  .ant-message-notice-content {
    background-color: #f6ffed;
    border: 1px solid #b7eb8f;
  }
}

.custom-message-warning {
  text-align: right !important;

  .ant-message-notice-content {
    background-color: #fffbe6;
    border: 1px solid #ffe58f;
  }

}

.custom-modal {
  &.ant-modal-confirm .ant-modal-confirm-btns {
    float: right;
    margin-top: 24px;
    width: 100%;
    display: flex;

    .ant-btn {
      color: #fff;
      //background: #0A8FDC;
      //border-color: #0A8FDC;
      background: #ffd500;
      border-color: #ffd500;
      text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
      box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
      min-width: 80px;
    }

    .ant-btn-primary {
      margin-left: auto;
      background: #fff;
      //color:#0A8FDC;
      color:#ffd500;
    }

  }
}

.custom-btn {

  &.btn-primary {
    color: #fff;
    background-color: @primary-color;
    border-color: @primary-color;
  }

  &.btn-secondary {
    color: #fff;
    background-color: @secondary-color;
    border-color: @secondary-color;
  }

  &.btn-danger {
    color: #fff;
    background-color: @danger-color;
    border-color: @danger-color;

  }

  &.btn-warning {
    color: #212529;
    background-color: @warning-color;
    border-color: @warning-color;
  }

  &.btn-success {
    color: #fff;
    background-color: @success-color;
    border-color: @success-color;
  }

  &.btn-info {
    color: #fff;
    background-color: @info-color;
    border-color: @info-color;
  }

  &.btn-dark {
    color: #fff;
    background-color: @dark-color;
    border-color: @dark-color;
  }

}

.table-responsive {
  min-height: .01%;
  overflow-x: auto;
}

@media screen and (max-width: 767px) {
  .table-responsive {
    width: 100%;
    margin-bottom: 15px;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: 1px solid #ddd;
  }
}