.flag-icon,
.flag-icon-background {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}

.flag-icon:before {
  content: "\00a0";
}

.flag-icon {
  position: relative;
  display: inline-block;
  width: 1.33333em;
  line-height: 1em;
  border-radius: 2px;
  box-shadow: inset 0 0 1px 0 rgb(0 0 0 / 70%);
}

.flag-icon-oms {
  background-image: url(../../assets/flag/oms.png);
}

.flag-icon-tor {
  background-image: url(../../assets/flag/tor.png);
}

.flag-icon-la, .flag-icon-las {
  background-image: url(../../assets/flag/la.svg);
}

.flag-icon-my {
  background-image: url(../../assets/flag/my.svg);
}

.flag-icon-vn, .flag-icon-vn4, .flag-icon-vn5, .flag-icon-vnp, .flag-icon-vns {
  background-image: url(../../assets/flag/vn.svg);
}

.flag-icon-jp {
  background-image: url(../../assets/flag/jp.svg);
}

.flag-icon-hk {
  background-image: url(../../assets/flag/hk.svg);
}

.flag-icon-cn {
  background-image: url(../../assets/flag/cn.svg);
}

.flag-icon-kr {
  background-image: url(../../assets/flag/kr.svg);
}

.flag-icon-tw {
  background-image: url(../../assets/flag/tw.svg);
}

.flag-icon-sg {
  background-image: url(../../assets/flag/sg.svg);
}

.flag-icon-in {
  background-image: url(../../assets/flag/in.svg);
}

.flag-icon-eg {
  background-image: url(../../assets/flag/eg.svg);
}

.flag-icon-de {
  background-image: url(../../assets/flag/de.svg);
}

.flag-icon-gb {
  background-image: url(../../assets/flag/gb.svg);
}

.flag-icon-ru {
  background-image: url(../../assets/flag/ru.svg);
}

.flag-icon-us {
  background-image: url(../../assets/flag/us.svg);
}