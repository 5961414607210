/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.auth {
  flex: 1;
  display: flex;
  position: relative;
  min-height: 100vh;
}
.auth .main-content-view {
  padding: 20px;
}
.auth .main-auth-scrollbar {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.auth .footer {
  margin-right: 0;
  margin-left: 0;
}
@media screen and (min-width: 1280px) {
  .boxedLayout {
    max-width: 1260px;
    margin-left: auto;
    margin-right: auto;
  }
  .boxedLayout #root {
    -webkit-box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.12);
  }
  .boxedLayout .appMainFixedFooter .app-main-footer {
    max-width: 1260px;
    margin-left: auto;
    margin-right: auto;
  }
  .boxedLayout .mini-sidebar-toggle.ant-layout-sider,
  .boxedLayout .mini-sidebar.ant-layout-sider,
  .boxedLayout .userMiniHeader-sidebar.ant-layout-sider {
    position: absolute;
  }
  .boxedLayout .app-header-mini-sidebar,
  .boxedLayout .app-header-mini,
  .boxedLayout .app-userMiniHeader {
    position: absolute;
    width: 100% !important;
  }
  .boxedLayout .mini-sidebar-toggle.ant-layout-sider-collapsed + .app-layout-mini-sidebar-main .app-header-mini-sidebar,
  .boxedLayout .mini-sidebar.ant-layout-sider-collapsed + .app-layout-mini-main .app-header-mini,
  .boxedLayout .userMiniHeader-sidebar.ant-layout-sider-collapsed + .app-layout-userMiniHeader-main .app-userMiniHeader {
    width: 100% !important;
  }
  .boxedLayout .app-layout-mini-sidebar,
  .boxedLayout .app-layout-mini,
  .boxedLayout .app-layout-userMiniHeader {
    padding-top: 0;
  }
  .boxedLayout .app-layout-mini-sidebar-main,
  .boxedLayout .app-layout-mini-main,
  .boxedLayout .app-layout-userMiniHeader-main {
    padding-top: 71px;
  }
  .boxedLayout .coin-stats-content h3 {
    font-size: 16px;
  }
  .boxedLayout .coin-stats-content span {
    font-size: 12px;
  }
}
@media screen and (min-width: 1600px) {
  .boxedLayout {
    max-width: 1460px;
  }
  .boxedLayout .appMainFixedFooter .app-main-footer {
    max-width: 1460px;
  }
}
@media screen and (min-width: 1280px) {
  .framedLayout {
    padding: 20px;
    background-color: #6c757d;
  }
  .framedLayout #root {
    height: calc(100vh - 2 * 20px);
    overflow: hidden;
    -webkit-box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.12);
    border-radius: 12px;
  }
  .framedLayout.framedHorLayout #root,
  .framedLayout.framedHorDarkLayout #root,
  .framedLayout.framedHorHeaderFixedLayout #root {
    overflow-y: auto;
  }
  .framedLayout .app-sidebar-scrollbar,
  .framedLayout .main-scrollbar,
  .framedLayout .app-mini-sidebar-scrollbar,
  .framedLayout .main-mini-sidebar-scrollbar,
  .framedLayout .main-mini-scrollbar,
  .framedLayout .standard-main-scrollbar,
  .framedLayout .userHeader-main-scrollbar,
  .framedLayout .main-userMiniHeader-scrollbar,
  .framedLayout .drawerLayout-main-scrollbar,
  .framedLayout .app-BitBucket-sidebar-scrollbar {
    max-height: calc(100vh - (71px + 2 * 20px));
  }
  .framedLayout .app-standard-sidebar-scrollbar {
    max-height: calc(100vh - (140px + 2 * 20px));
  }
  .framedLayout .app-userHeader-sidebar-scrollbar,
  .framedLayout .app-sidebar-userMiniHeader-scrollbar,
  .framedLayout .bitBucket-main-scrollbar,
  .framedLayout .bucket-minibar {
    max-height: calc(100vh - (2 * 20px));
  }
  .framedLayout .app-layout {
    height: calc(100vh - 2 * 20px);
    min-height: 10px;
  }
  .framedLayout .auth {
    min-height: 10px;
    height: 100%;
  }
  .framedLayout .main-auth-scrollbar {
    min-height: 10px;
  }
  .framedLayout .apps-container {
    height: calc(100vh - (153px + 2 * 20px));
  }
  .framedLayout .app-layout-mini-sidebar,
  .framedLayout .app-layout-mini,
  .framedLayout .app-layout-hor,
  .framedLayout .app-layout-hor-header-fixed {
    min-height: 100%;
  }
  .framedLayout .mini-sidebar-toggle.ant-layout-sider,
  .framedLayout .mini-sidebar.ant-layout-sider,
  .framedLayout .userMiniHeader-sidebar.ant-layout-sider {
    position: absolute;
  }
  .framedLayout .app-header-mini-sidebar,
  .framedLayout .app-header-mini,
  .framedLayout .app-userMiniHeader {
    position: absolute;
    width: 100% !important;
  }
  .framedLayout .mini-sidebar-toggle.ant-layout-sider-collapsed + .app-layout-mini-sidebar-main .app-header-mini-sidebar,
  .framedLayout .mini-sidebar.ant-layout-sider-collapsed + .app-layout-mini-main .app-header-mini,
  .framedLayout .userMiniHeader-sidebar.ant-layout-sider-collapsed + .app-layout-userMiniHeader-main .app-userMiniHeader {
    width: 100% !important;
  }
  .framedLayout .app-layout-mini-sidebar,
  .framedLayout .app-layout-mini,
  .framedLayout .app-layout-userMiniHeader {
    padding-top: 0;
  }
  .framedLayout .app-layout-mini-sidebar-main,
  .framedLayout .app-layout-mini-main,
  .framedLayout .app-layout-userMiniHeader-main {
    padding-top: 71px;
  }
}
