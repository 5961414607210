.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0,0,0,.125);
  border-radius: 0.75rem;
}

.stake__detail {
  border: none;
  margin-bottom: 0.5rem;
  overflow: hidden;



  .detail-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem 0;

    & +.detail-item {
      border-left: 1px solid #239be7;
    }

    .title {
      color: #6c757d;
      margin-bottom: 0;
    }

    .value {
      color: #000;

      &.success {
        color:#28a745;
      }


    }

  }

  .detail-header {
    display: flex;
    justify-content: center;
    background: linear-gradient(180deg,#2c9fe8,#5ab4ed);
    color: #fff;
    padding: 0.5rem;

    .title {
      color:#fff;
      margin-bottom: 0;
    }

  }

}

.stake__number {
  border: none;
  border-radius: 0.3rem;
  margin-bottom: 0.5rem;
  overflow: hidden;

  .number-header {
    background: linear-gradient(180deg,#2c9fe8,#5ab4ed);
    color: #fff;
    padding: 0.25rem 0.5rem;

    .title {
      margin-bottom: 0;
      color: #fff;
    }
  }

  .number-body {
    display: flex;

    .body-left {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 25%;
      min-width: 120px;

      .title {
        margin-bottom: 0;
        //color: #fff;
      }

      .value {
        color: #0081c6;
        margin-bottom: 0;
      }

    }

    .body-right {
      flex: 1;
      padding: 0.5rem;
      border-left: 1px solid #239be7;
    }

    .number-status {
      display: flex;
      justify-content: center;
      background: #e9ecef;
      padding: 0.5rem;
      border-radius: 0.5rem;

      &.success {
        background-color: #28a745;
         .text-muted {
           color:#fff !important;
         }
      }
    }

  }

}

.stake-btn.disabled,
.stake-btn:disabled {
  color: #212529;
  background-color: #e9ecef;
  border-color: #e9ecef;
  opacity: .65;
}
@primary-color: #ffd500;